<template>
  <div>
    <v-container fluid class="px-0">
      <v-card outlined elevation="3">
        <v-card-text>
          <v-data-table
            class="black--text"
            :headers="headers"
            :search="search"
            :items="dataTipePengeluaran"
            dense
          >
            <template v-slot:item.trx_type="{ item }">
              {{ formatTrx(item.trx_type) }}
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import axios from "axios";

export default {
  data: () => ({
    search: "",
    headers: [
      {
        text: "Inisial",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "inisial",
      },
      {
        text: "Tipe",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "trx_type",
      },
      {
        text: "Jenis",
        class: "text-subtitle-2 font-weight-bold black--text",
        value: "name",
      },
    ],
    dataTipePengeluaran: [],
  }),

  methods: {
    ...mapActions({
      refreshAction: "auth/refresh",
    }),

    getData() {
      axios
        .get("v1/user/Scan/Out")
        .then((response) => {
          this.dataTipePengeluaran = response.data.data;
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.refreshAction(localStorage.getItem("refresh"));
              this.getData();
            }
            console.clear();
          }
        });
    },
    formatTrx(value) {
      if (value === "OUT") {
        return "Pengeluaran";
      }
    },
  },

  mounted() {
    this.getData();
  },
};
</script>